
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { config } from "@/core/helpers/config";
import { useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import PolicySummary from "@/components/reusable/policy-information/PolicySummary.vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "client-house-insurance-policy-information",
  components: {
    PolicySummary,
  },
  setup() {
    const { reinitialization, useReusableNavigation } = ResusableFunctions();

    const router = useRouter();
    const tabIndex = ref(0);
    const policySummary = ref({});
    const buildings = ref([
      {
        building: {
          buildingReinstatementValue: 0,
          alternativeAccommodation: 0,
        },
      },
    ]);
    const creditNotes = ref([]);
    const publicId = router.currentRoute.value.params.publicId;
    const isOwnedBuilding = ref(true);
    const isRentedBuilding = ref(false);

    /**
     * Set active tab when the tab get clicked
     * @param event
     */
    const setActiveTab = (event) => {
      tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
      // keep active tab
      localStorage.setItem("builderTab", tabIndex.value.toString());
    };

    // Function to get underwriters
    const getPolicyDetails = async () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${variables.UNDERWRITERS_POLICY_DETAILS_ROUTE}/${publicId}/details`
        )
          .then(({ data }) => {
            policySummary.value = data.data.policySummary;
            buildings.value = data.data.items;
            creditNotes.value = data.data.creditNotes;
            isOwnedBuilding.value = true;
            isRentedBuilding.value = false;

            if (buildings.value?.[0]?.building) {
              const reinstatementValue =
                buildings.value[0].building.buildingReinstatementValue;

              if (reinstatementValue === 0) {
                isOwnedBuilding.value = false;
                isRentedBuilding.value = true;
              } else {
                isOwnedBuilding.value = true;
                isRentedBuilding.value = false;
              }
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    onMounted(() => {
      getPolicyDetails();

      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("builderTab") || "0");
      setCurrentPageBreadcrumbs("House Insurance", [
        "Policy Listing",
        "Policy Information",
      ]);
    });

    return {
      tabIndex,
      config,
      setActiveTab,
      policySummary,
      buildings,
      isOwnedBuilding,
      isRentedBuilding,
      useReusableNavigation,
      reinitialization,
      creditNotes,
    };
  },
  created() {
    //Set page title
    document.title =
      "Underwriter Building House Insurance Policy Information | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
  },
});
